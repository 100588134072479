import React, { useEffect, useMemo, useRef, useState, useContext } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { formatDate } from "../../../helpers/dates";
import { useAxiosSWR } from "root/axiosInstance";
import AppContext, { URL_TYPES } from "src/context";
import { LiaShareSolid } from "react-icons/lia";
import { MdZoomIn } from "react-icons/md";
import { MdOutlineZoomOut } from "react-icons/md";
import { IoMdHeart } from "react-icons/io";
import { ShimmerEffect } from "../../common/ShimmerEffect";
import SEOMeta from "../Home/common/SEOMeta";
import MoreStories from "../../common/MoreStories";
import { NewsNames, SocialLinksComponent, socialLinksV2 } from "./general";
import { HiMiniXMark } from "react-icons/hi2";
import { FaInfo } from "react-icons/fa";
import { NewsFAQs } from "./newsFAQs";
import NewsMatchInfo from "./fantasyComponents/NewsMatchInfo";
import { TableOfContents } from "./fantasyComponents/TableOfContents";
import FantasyCommon from "../../common/FantasyCommon";
import { doScrollToTop } from "../../../helpers/general";
import { AnalyticsCategory, TrackGoogleAnalyticsEvent } from "../../../helpers/analytics";

const headingTag = {
  h1: 10,
  h2: 6,
  h3: 3,
  h4: 0,
  h5: -2,
  h6: -4
}

const LinksByType = {
  top_story: '/cricket-top-story',
  weekly_recap: '/cricket-news',
  news: '/cricket-news',
  fantasy: '/fantasy'
};

const NewsDetails = () => {
  const { id: slug } = useParams();
  const [seoMeta, setSEOMeta] = useState(null);
  const [act, setAct] = useState(null)
  const [isLiked, setIsLiked] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [fontSize, setFontSize] = useState(document.body.clientWidth > 700 ? 18 : 14);
  const [isContentExpanded, setIsContentExpanded] = useState(false);
  const [likeCount, setLikeCount] = useState(0)
  const containerRef = useRef();
  const { pathname: pathName } = useLocation();

  const current = containerRef.current;
  const memoizedValues = useMemo(() => {
    const parts = slug.split("-");
    const newsId = parts[parts.length - 1];
    return { newsId };
  }, [slug]);
  const { newsId } = memoizedValues;
  const {
    data: dataObj,
    isLoading,
    error: isError,
    mutate
  } = useAxiosSWR(`/v1/news/${newsId}`);

  useEffect(() => {
    if (dataObj) {
      try {
        (function (w, q) {
          console.log("Script called:", q);
          w[q] = w[q] || [];
          w[q].push(["_mgc.load"]);
        })(window, "_mgq");
      } catch (error) {
        console.log('Error occurred while fetching Ad by mgc')
      }
    }
  }, [pathName, dataObj]);

  const appContext = useContext(AppContext);

  const newsTitle = dataObj?.title;
  useEffect(() => {
    const urlInfoObj = {
      type: URL_TYPES.NEWS_DETAILS,
      path: pathName,
      reqState: {
        data: {
          title: newsTitle,
        },
      }
    };
    appContext.updateUrlInfo(urlInfoObj);
  }, [pathName, newsTitle]);

  const isFantasyNews = dataObj?.type === 'fantasy';

  const {
    data: likeUnlikeData,
    isLoading: likeLoading,
    error: likeError
  } = useAxiosSWR(act ? `/v1/news/${newsId}/${act}` : null)
  useEffect(() => {
    if (likeUnlikeData) {
      setLikeCount(likeUnlikeData?.likes)
      setIsLiked(act === "like" ? true : false)
    }
  }, [likeUnlikeData])
  const dataObject = dataObj || {};
  useEffect(() => {
    if (dataObject?.info) {
      setSEOMeta({
        title: dataObject?.info?.metaTitle || dataObject?.title,
        description: dataObject?.info?.metaDescription,
        image: dataObject?.image
      });
    }
    if (dataObject?.cnt_likes) {
      setLikeCount(dataObject?.cnt_likes)
      setIsLiked(dataObject?.is_liked)
    }
    if (dataObject?.info?.showReadMore) {
      setIsContentExpanded(true)
    }
  }, [dataObject]);
  useEffect(() => {
    if (current && dataObj) {
      try {
        const tableElements = current.getElementsByTagName("table");
        for (let i = 0; i < tableElements.length; i++) {
          const currentTable = tableElements[i];
          const parentElement = currentTable.parentElement;
          parentElement.style.overflowX = 'auto';
        }
        const headingElements = current.querySelectorAll("h1, h2, h3, h4, h5, h6");
        headingElements.forEach(heading => {
          const tagName = heading.tagName.toLowerCase();
          const adjustment = headingTag[tagName];
          heading.style.fontSize = `${fontSize + adjustment}px`;
        });
      } catch (error) {
      }
    }
  }, [current, dataObj, fontSize]);


  const newsType = dataObject?.type;
  useEffect(() => {
    setIsContentExpanded(false);
  }, [slug]);

  useEffect(() => {
    let twitterScript = null;
    let instaScript = null;
    if (dataObject && dataObject.content && dataObject.content.includes('twitter')) {
      twitterScript = document.createElement('script');
      twitterScript.charset = 'utf-8';
      twitterScript.src = `https://platform.twitter.com/widgets.js?version=${Math.random() + '_' + Date.now()}`;
      twitterScript.async = true;

      document.body.appendChild(twitterScript);
    }
    if (dataObject && dataObject.content && dataObject.content.includes('www.instagram')) {
      instaScript = document.createElement('script');
      instaScript.src = `//www.instagram.com/embed.js?version=${Math.random() + '_' + Date.now()}`;
      instaScript.async = true;

      document.body.appendChild(instaScript);
    }
    return () => {
      twitterScript && document.body.removeChild(twitterScript);
      instaScript && document.body.removeChild(instaScript);
    };
  }, [dataObject]);

  if (!isLoading && !dataObject && !isError) {
    return null;
  }
  if (isLoading) {
    return <div className="min-h-screen"></div>;
  }
  if (isError) {
    return (
      <div></div>
    )
  }

  const handleLike = () => {
    if (act === "like" || isLiked) {
      setAct("unlike");
    } else if (act === "unlike" || !isLiked) {
      setAct("like");
    }
    TrackGoogleAnalyticsEvent(AnalyticsCategory.ARTICLE, 'article_like', window.location.pathname)
  };

  const handleShareClick = (e) => {
    e.preventDefault();
    try {
      if (navigator.share) {
        navigator.share({
          title: dataObject?.title || "",
          text: dataObject?.title || "",
          url: window.location.href,
        });
        return;
      }
    } catch (error) {
      console.log('Sharing failed.. now sharing manually... , Error is ', error);
    }
    try {
      navigator.clipboard.writeText(window.location.href);
    } catch (error) {
    }
    setShowPopup(!showPopup);
    TrackGoogleAnalyticsEvent(AnalyticsCategory.ARTICLE, 'article_share', window.location.pathname)
  };
  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleZoomIn = () => {
    setFontSize((prevSize) => Math.min(prevSize + 1, 30));
  };

  const handleZoomOut = () => {
    setFontSize((prevSize) => Math.max(prevSize - 1, 12));
  };

  return (
    <div>
      {showPopup && <div className="overlay z-40" onClick={handleClosePopup} />}
      <div>
        {showPopup && (
          <div className="modal-content fixed bg-white shadow-md md:py-4 md:px-[30px] p-4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-40 rounded-2xl">
            <SocialLinksComponent
              socialLinks={socialLinksV2}
              className="container"
              type="share"
              textInputValue={window.location.href}
              closeIcon={<HiMiniXMark className="md:w-6 md:h-6 w-5 h-5 border border-[#E3E3E4] text-gray-400 rounded-full cursor-pointer" onClick={handleClosePopup} />} />
          </div>
        )}
        <div className="rounded-2xl md:border md:mt-4 md:bg-white md:mx-0 mx-[6px] mt-[6px]">
          <div className={`md:pt-10 article-font relative ${isContentExpanded ? 'overflow-hidden md:rounded-b-xl' : ""} `}
            style={{ maxHeight: isContentExpanded ? '1000px' : 'none' }}
          >
            {dataObj !== undefined ? <>
              <div>
                {seoMeta && <SEOMeta data={seoMeta} />}
                <div className="bg-white rounded-2xl md:rounded-none border md:border-none">
                  <div className="text-[#646464] md:text-sm text-[9px] inline-block px-4 md:px-10 md:pt-0 pt-4">
                    <Link to={"/"} className="text-[#3A32D1]"> Home </Link>
                    <span>{">"}</span>
                    <Link to={LinksByType[dataObj.type] || '/cricket-news'}>
                      <span className="ml-1 text-[#3A32D1]">{NewsNames[dataObj.type]}</span>
                    </Link>
                  </div>
                  <div className="px-4 md:px-10">
                    <h1
                      className="font-semibold lg:text-3xl text-lg border-b pt-2 pb-4 md:leading-[32px] leading-[27px]"
                    >{dataObject.title}</h1>
                    <p className="lg:text-xl md:text-lg py-5 text-sm font-[400]">{dataObject.excerpt}</p>
                    <div className="flex gap-10">
                      <p className="lg:text-sm text-[#6c6c6c96] text-[10px] font-semibold">{formatDate(dataObject.createdAt)}</p>
                      <p className="lg:text-sm text-[#6c6c6c96] text-[10px] font-semibold">By {dataObject?.author?.name || "Cricket Gully"}</p>
                    </div>
                  </div>
                  <div className="mt-4 px-4 md:px-10 relative">
                    <ShimmerEffect src={dataObject.image} alt={dataObj?.info?.imageAltText || dataObject.title} className="rounded-xl w-full md:h-[490px] h-[181px]" />
                    {showPopup ? null :
                      <div className="absolute bg-white z-10 md:bottom-6 bottom-3 rounded-full ml-[12px] group flex items-center">
                        {dataObject?.image_source ? (
                          <>
                            <FaInfo className="w-7 h-7 p-2" />
                            <span className="hidden group-hover:inline-block md:text-sm text-[10px] rounded pr-4">
                              {dataObject?.image_source}
                            </span>
                          </>
                        ) : null}
                      </div>
                    }
                  </div>
                  <div className="sticky z-30 md:top-[60px] top-[48px] bg-white md:p-2 md:py-6 p-4 border-b md:px-10">
                    <div className="flex justify-between">
                      <div className="flex items-center">
                        <div className="bg-[#FFFFFF] md:p-3 p-2 rounded-full shadow-md md:w-12 md:h-12 w-8 h-8 cursor-pointer" onClick={handleLike}>
                          {
                            !likeLoading &&
                            <IoMdHeart
                              className={`md:h-6 h-4 md:w-6 w-4 ${isLiked ? 'text-[#D6313E]' : 'text-[#000000]'}`}
                            />
                          }
                        </div>
                        <div className="ml-2 md:text-[20px] text-xs md:pt-0 pt-1 md:leading-8">{likeCount}
                          <p className="lg:text-sm text-[#6c6c6c96] text-[8px] font-semibold">Liked this post</p>
                        </div>
                      </div>
                      <div
                        className="bg-[#FFFFFF] md:p-3 p-2 rounded-full shadow-md md:w-12 md:h-12 w-8 h-8 cursor-pointer"
                        onClick={handleShareClick}
                      >
                        <LiaShareSolid className="md:h-6 h-4 md:w-6 w-4" />
                      </div>
                      <div
                        className="bg-[#FFFFFF] md:p-3 p-2 rounded-full shadow-md md:w-12 md:h-12 w-8 h-8 cursor-pointer"
                        onClick={handleZoomIn}
                      >
                        <MdZoomIn className="md:h-6 h-4 md:w-6 w-4" />
                      </div>
                      <div
                        className="bg-[#FFFFFF] md:p-3 p-2 rounded-full shadow-md md:w-12 md:h-12 w-8 h-8 cursor-pointer"
                        onClick={handleZoomOut}
                      >
                        <MdOutlineZoomOut className="md:h-6 h-4 md:w-6 w-4" />
                      </div>
                    </div>
                  </div>

                  {isFantasyNews && dataObj?.match_id && <TableOfContents newsObj={dataObj} />}

                  <div className="md:px-10 md:pt-10 pb-0 px-4 py-6">
                    <div
                      ref={containerRef}
                      className={`leading-[${fontSize + 4}] newsContentDiv font-[400]`}
                      style={{ fontSize: `${fontSize}px` }}
                      dangerouslySetInnerHTML={{ __html: dataObject.content }}
                    />
                  </div>
                  {
                    !isFantasyNews &&
                    <div className="flex gap-2 md:pt-8 pt-4 md:justify-start justify-center flex-wrap text-nowrap px-4 md:px-10">
                      {dataObject?.tags?.map((tag, index) => (
                        <Link key={index} to={`/tag/${encodeURIComponent(tag.keyword_slug)}`} onClick={doScrollToTop}>
                          <div color="indigo" className="text-[#3A32D1] bg-[#3A32D1] bg-opacity-10 font-semibold md:text-[18px] text-xs px-6 md:px-[24px] md:py-3 py-2 rounded-[50px]">
                            {tag.keyword}
                          </div>
                        </Link>
                      ))}
                    </div>
                  }
                  <NewsFAQs content={dataObj?.info?.faqs} />
                  <div className='md:p-10 p-4 py-6'>
                    <div className="flex items-center min-w-[50px]">
                      <span className="custom-border-left mr-3 md:mr-6"></span>
                      <p className='text-sm text-[#787878]'>Thank You</p>
                      <span className="custom-border-right ml-3 md:ml-6"></span>
                    </div>
                  </div>
                </div>
                {isContentExpanded && (
                  <div className="absolute bottom-0 left-0 right-0 flex md:rounded-b-xl items-end justify-center w-full pb-4 h-[150px] bg-gradient-to-t from-white to-transparent">
                    <button
                      onClick={() => setIsContentExpanded(false)}
                      className="text-[#3A32D1] font-semibold text-xs bg-white rounded-full py-3 px-8 shadow-lg"
                    >
                      Read More
                    </button>
                  </div>
                )}
                {isFantasyNews && dataObj?.match_id &&
                  <div className="md:px-10">
                    <NewsMatchInfo newsObj={dataObj} />
                  </div>
                }
              </div>
              {
                !isFantasyNews &&
                <div className="bg-white rounded-2xl md:bg-none md:border-none border py-3 mt-5">
                  <SocialLinksComponent socialLinks={socialLinksV2} className="md:px-10 md:py-0 py-4" />
                </div>
              }
            </> :
              <div>
                <h3>No Data Found...</h3>
              </div>
            }
          </div>
        </div>
      </div>
      {
        isFantasyNews && dataObj?.match_id &&
        <>
          <div className='md:px-10 md:py-8 py-4 mx-[6px] md:mx-0 border bg-white rounded-2xl mt-5'>
            <FantasyCommon matchId={dataObject?.match_id} readFullArticle={false} pageType={"fantasy-details"} />
            <div className="flex gap-2 md:pt-8 pt-4 md:justify-start justify-center flex-wrap text-nowrap">
              {dataObject?.tags?.map((tag, index) => (
                <Link key={index} to={`/tag/${encodeURIComponent(tag.keyword)}`} onClick={doScrollToTop}>
                  <div key={index} color="indigo" className="text-[#3A32D1] bg-[#3A32D1] bg-opacity-10 font-semibold md:text-[18px] text-xs px-6 md:px-[24px] md:py-3 py-2 rounded-[50px]">{tag.keyword}</div>
                </Link>
              ))}
            </div>
            <div className="bg-white md:border-none border py-3 mt-5 hidden md:block">
              <SocialLinksComponent socialLinks={socialLinksV2} />
            </div>
          </div>
          <div className="bg-white border mx-[6px] py-3 mt-5 md:hidden rounded-2xl">
            <SocialLinksComponent socialLinks={socialLinksV2} />
          </div>
        </>
      }
      <div className="mt-4 md:mx-0 mx-4 hidden md:block">
        <MoreStories newsType={newsType} currentNewsID={newsId} />
      </div>
      <div data-type="_mgwidget" data-widget-id="1675321" className="m-[6px] md:m-0">
      </div>
    </div>
  );
};

export default NewsDetails;
